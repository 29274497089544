
import { defineComponent } from "vue";

export default defineComponent({
  name: "PaginationControls",
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    totalPages: {
      type: Number,
      required: true
    }
  },
  emits: {
    updatePage: null
  },
  setup: (props, instance) => {
    const buildUpdateCallback = (page: number) => () => {
      instance.emit("updatePage", page);
    };
    return { buildUpdateCallback };
  }
});
