
import { defineComponent, ref } from "vue";
const dropdownComponent = defineComponent({
  name: "Dropdown",
  props: {
    label: {
      type: String,
      default: ""
    },
    firstItem: {
      type: String,
      default: ""
    },
    items: {
      type: [Array]
    },
    backgroundColor: {
      type: String,
      default: "#FFFFFF"
    },
    textColor: {
      type: String,
      default: "black"
    },
    borderStyle: {
      type: String,
      default: ""
    },
    stringPropSpecifier: {
      type: String,
      default: ""
    }
  },
  emits: ["itemSelected"],
  setup: (props, context) => {
    const dropdownClass = ref("");
    const hasItems = () => {
      if (props.items) {
        if (props.items.length > 0) {
          return true;
        }
      }
      return false;
    };
    const toggleDropdown = () => {
      if (hasItems()) {
        dropdownClass.value =
          dropdownClass.value === "is-active" ? "" : "is-active";
      }
    };
    const itemSelected = (item: object) => {
      dropdownClass.value = "";
      context.emit("itemSelected", item);
    };
    return {
      toggleDropdown,
      dropdownClass,
      itemSelected,
      hasItems
    };
  }
});
export default dropdownComponent;
