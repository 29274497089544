
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "Loader",
  props: {
    width: {
      type: Number,
      optional: true,
      default: 120
    },
    height: {
      type: Number,
      optional: true,
      default: 120
    }
  },
  setup: props => {
    const borderWidth = computed(() => props.width / 7.5);
    return {
      borderWidth
    };
  }
});
