<template>
  <div>Hello world!</div>
</template>

<script>
export default {
  name: "TeamsAdmin"
};
</script>

<style scoped></style>
