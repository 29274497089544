
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "Search",
  setup: () => {
    const query = ref<string>("");

    return {
      query
    };
  }
});
