
import { defineComponent } from "vue";
import store from "@/store";

export default defineComponent({
  name: "Error",
  setup() {
    const error = store.getters.error.message;
    const isInDevMode = process.env.NODE_ENV === "development";
    return {
      isInDevMode,
      error
    };
  }
});
