<template>
  <div>Hello World!</div>
</template>

<script>
export default {
  name: "TeamsMembers"
};
</script>

<style scoped></style>
