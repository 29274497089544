
import { defineComponent, onMounted } from "vue";
import Card from "@/components/Card";
import TicketTable from "./components/TicketTable.vue";
import TicketInput from "./components/TicketInput.vue";
import { apiExecutor } from "@/methods";
import { getUsersPinnedTickets, getUsersAssignedTickets } from "@/api";

export default defineComponent({
  name: "Home",
  components: {
    Card,
    TicketTable,
    TicketInput
  },
  setup: () => {
    const [queryUsersPinned, usersPinnedState] = apiExecutor(
      getUsersPinnedTickets
    );

    const [queryUsersAssigned, usersAssignedState] = apiExecutor(
      getUsersAssignedTickets
    );

    onMounted(() => {
      queryUsersPinned();
      queryUsersAssigned();
    });

    return {
      usersPinnedState,
      usersAssignedState
    };
  }
});
