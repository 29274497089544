
/* eslint-disable @typescript-eslint/camelcase */

import { computed, defineComponent, onMounted } from "vue";
import router from "@/router";
import TeamEntry from "./TeamEntry.vue";
import { ReadTeamRecord } from "@/api/types";
import { getUsersTeams } from "@/api";
import { apiExecutor } from "@/methods";

export default defineComponent({
  name: "Sidebar",
  components: {
    TeamEntry
  },
  setup: () => {
    const currentRouteName = computed<string | symbol | null | undefined>(
      () => router.currentRoute.value.name
    );

    const [
      queryUsersTeams,
      { data: teams, loading: loadingTeams, error }
    ] = apiExecutor<ReadTeamRecord[]>(getUsersTeams);

    const selectedView = computed<[string?, string?, string?]>(() => {
      // split always yields an empty string at the beginning of the path,
      // hence we omit it
      const [, ...tokens] = router.currentRoute.value.path.split("/");

      return [tokens[0], tokens[1], tokens[2]];
    });

    onMounted(queryUsersTeams);

    return {
      selectedView,
      currentRouteName,
      loadingTeams,
      teams,
      error
    };
  }
});
