<template>
  <div>
    <svg
      :width="width"
      :height="height"
      viewBox="0 0 1665 2023"
      xmlns="http://www.w3.org/2000/svg"
      xml:space="preserve"
      style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;"
    >
      <g transform="matrix(1,0,0,1,-47688.7,-18335)">
        <g id="Logo" transform="matrix(0.233142,0,0,0.27255,36621,13340.6)">
          <rect
            x="47472.1"
            y="18324.6"
            width="7139.9"
            height="7421.9"
            style="fill:none;"
          />
          <g transform="matrix(1.23032,0,0,1.01244,-12163.4,-268.269)">
            <g
              transform="matrix(2.0625,2.67697e-16,-5.72459e-17,1.2056,20357.9,11313.4)"
            >
              <g
                transform="matrix(-0.469501,0.389335,-0.188877,-0.720311,17202.5,10856.6)"
              >
                <path
                  d="M5524.11,1990.01C5524.11,1565.21 5226.57,1220.32 4860.09,1220.32L1637.59,1220.32C1271.11,1220.32 973.571,1565.21 973.571,1990.01L973.571,5001.18C973.571,5425.98 1271.11,5770.86 1637.59,5770.86L4860.09,5770.86C5226.57,5770.86 5524.11,5425.98 5524.11,5001.18L5524.11,1990.01Z"
                  style="fill:rgb(160,160,160);"
                />
              </g>
              <g
                transform="matrix(-0.469501,0.389335,-0.188877,-0.720311,17243.2,10652.9)"
              >
                <path
                  d="M5524.11,1990.01C5524.11,1565.21 5226.57,1220.32 4860.09,1220.32L1637.59,1220.32C1271.11,1220.32 973.571,1565.21 973.571,1990.01L973.571,5001.18C973.571,5425.98 1271.11,5770.86 1637.59,5770.86L4860.09,5770.86C5226.57,5770.86 5524.11,5425.98 5524.11,5001.18L5524.11,1990.01Z"
                  style="fill:rgb(208,208,208);"
                />
              </g>
              <g
                transform="matrix(-0.328073,0.272055,-0.131981,-0.503331,16580.8,10265.9)"
              >
                <path
                  d="M5524.11,1990.01C5524.11,1565.21 5226.57,1220.32 4860.09,1220.32L1637.59,1220.32C1271.11,1220.32 973.571,1565.21 973.571,1990.01L973.571,5001.18C973.571,5425.98 1271.11,5770.86 1637.59,5770.86L4860.09,5770.86C5226.57,5770.86 5524.11,5425.98 5524.11,5001.18L5524.11,1990.01Z"
                  style="fill:rgb(45,45,45);"
                />
              </g>
            </g>
            <g transform="matrix(2.67044,0,0,1.06127,12856.3,11639.1)">
              <g
                transform="matrix(0.438238,-0.534518,0.226202,1.26883,12145.9,6321.35)"
              >
                <path
                  d="M3343.7,4129.31C3343.7,4049.96 3272.39,3985.54 3184.56,3985.54L2340.62,3985.54C2252.78,3985.54 2181.47,4049.96 2181.47,4129.31L2181.47,5004C2181.47,5083.35 2252.78,5147.77 2340.62,5147.77L3184.56,5147.77C3272.39,5147.77 3343.7,5083.35 3343.7,5004L3343.7,4129.31Z"
                  style="fill:rgb(32,166,238);"
                />
                <path
                  d="M3343.7,4129.31C3343.7,4049.96 3272.39,3985.54 3184.56,3985.54L2340.62,3985.54C2252.78,3985.54 2181.47,4049.96 2181.47,4129.31L2181.47,5004C2181.47,5083.35 2252.78,5147.77 2340.62,5147.77L3184.56,5147.77C3272.39,5147.77 3343.7,5083.35 3343.7,5004L3343.7,4129.31ZM3226.48,4129.31L3226.48,5004C3226.48,5024.9 3207.7,5041.87 3184.56,5041.87C3184.56,5041.87 2340.62,5041.87 2340.62,5041.87C2317.48,5041.87 2298.7,5024.9 2298.7,5004C2298.7,5004 2298.7,4129.31 2298.7,4129.31C2298.7,4108.41 2317.48,4091.44 2340.62,4091.44C2340.62,4091.44 3184.56,4091.44 3184.56,4091.44C3207.7,4091.44 3226.48,4108.41 3226.48,4129.31Z"
                  style="fill-opacity:0.07;"
                />
              </g>
              <g
                transform="matrix(0.479823,-6.96694e-17,0,1.25505,12932.7,4964.48)"
              >
                <path
                  d="M2542.69,4312.41L3265.89,3975.14"
                  style="fill:none;stroke:black;stroke-opacity:0.07;stroke-width:122px;"
                />
              </g>
              <g transform="matrix(0.43701,0,0,1.14307,13098.8,5680.4)">
                <path
                  d="M2542.69,4312.41L3265.89,3975.14"
                  style="fill:none;stroke:black;stroke-opacity:0.07;stroke-width:134px;"
                />
              </g>
              <g
                transform="matrix(0.401661,-5.77204e-17,2.20673e-17,1.05061,13232.2,6320.6)"
              >
                <path
                  d="M2542.69,4312.41L3265.89,3975.14"
                  style="fill:none;stroke:black;stroke-opacity:0.07;stroke-width:145px;"
                />
              </g>
              <g
                transform="matrix(0.358123,5.77204e-17,0,0.936725,13387.8,7058.54)"
              >
                <path
                  d="M2542.69,4312.41L3265.89,3975.14"
                  style="fill:none;stroke:black;stroke-opacity:0.07;stroke-width:163px;"
                />
              </g>
              <g
                transform="matrix(0.438238,-0.534518,0.226202,1.26883,11872.9,6011.31)"
              >
                <path
                  d="M3343.7,4129.31C3343.7,4049.96 3272.39,3985.54 3184.56,3985.54L2340.62,3985.54C2252.78,3985.54 2181.47,4049.96 2181.47,4129.31L2181.47,5004C2181.47,5083.35 2252.78,5147.77 2340.62,5147.77L3184.56,5147.77C3272.39,5147.77 3343.7,5083.35 3343.7,5004L3343.7,4129.31Z"
                  style="fill:rgb(255,56,119);"
                />
                <path
                  d="M3343.7,4129.31C3343.7,4049.96 3272.39,3985.54 3184.56,3985.54L2340.62,3985.54C2252.78,3985.54 2181.47,4049.96 2181.47,4129.31L2181.47,5004C2181.47,5083.35 2252.78,5147.77 2340.62,5147.77L3184.56,5147.77C3272.39,5147.77 3343.7,5083.35 3343.7,5004L3343.7,4129.31ZM3226.48,4129.31L3226.48,5004C3226.48,5024.9 3207.7,5041.87 3184.56,5041.87L2340.62,5041.87C2317.48,5041.87 2298.7,5024.9 2298.7,5004C2298.7,5004 2298.7,4129.31 2298.7,4129.31C2298.7,4108.41 2317.48,4091.44 2340.62,4091.44C2340.62,4091.44 3184.56,4091.44 3184.56,4091.44C3207.7,4091.44 3226.48,4108.41 3226.48,4129.31Z"
                  style="fill-opacity:0.07;"
                />
              </g>
              <g transform="matrix(0.397529,0,0,1.0398,12894.6,5617.42)">
                <path
                  d="M2542.69,4312.41L3265.89,3975.14"
                  style="fill:none;stroke:black;stroke-opacity:0.07;stroke-width:147px;"
                />
              </g>
              <g transform="matrix(0.303952,0,0,0.795032,13187.8,6922.71)">
                <path
                  d="M2542.69,4312.41L3265.89,3975.14"
                  style="fill:none;stroke:black;stroke-opacity:0.07;stroke-width:192px;"
                />
              </g>
              <g transform="matrix(0.376756,0,0,0.985463,13046.7,6366.56)">
                <path
                  d="M2542.69,4312.41L3265.89,3975.14"
                  style="fill:none;stroke:black;stroke-opacity:0.07;stroke-width:155px;"
                />
              </g>
            </g>
            <g
              id="_-Curve-"
              transform="matrix(1.06845,0,0,1.11065,47296.2,17734.3)"
            >
              <path
                d="M4463.51,5399.01C4543.55,5272.89 4006.09,5645.78 4053.94,5488.19C4221.22,4937.21 4184.76,4244.27 3895.76,3761.49C3867.79,3714.77 3838.82,3672.14 3808.93,3633.24C3362.91,3052.65 4215.38,2433.51 4654.24,3486.98C5141.3,4656.15 4719.02,4998.72 4719.02,4998.72C4719.02,4998.72 5071.43,5129.36 4463.51,5399.01Z"
                style="fill:rgb(255,94,87);"
              />
              <path
                d="M4427.79,5501.28C4453.17,5510.14 4481.62,5509.49 4507.44,5498.04C4714.13,5406.36 4820.37,5324.96 4871.33,5261.38C4927.89,5190.81 4934.95,5128.39 4925.81,5078.29C4918.62,5038.93 4899.39,5004.63 4874.62,4976.8C4987.72,4792.84 5128.94,4344.75 4754.25,3445.32C4553.48,2963.39 4263.1,2802.89 4036.29,2807.84C3805.2,2812.89 3618.14,2982.1 3581.49,3215.39C3558.77,3360.02 3594.15,3531.47 3723.02,3699.24C3750.49,3734.99 3777.1,3774.19 3802.81,3817.13C4077.18,4275.48 4109.1,4933.62 3950.28,5456.72C3932.79,5514.32 3947.24,5553.65 3964.7,5579.4C3982.23,5605.25 4008.17,5624.76 4045.12,5632.69C4067.87,5637.58 4100.54,5637.4 4139.5,5627C4186.86,5614.37 4253.28,5584.49 4318.3,5552.76C4352.52,5536.07 4395.44,5516.12 4427.79,5501.28ZM4463.51,5399.01C4543.55,5272.89 4006.09,5645.78 4053.94,5488.19C4221.22,4937.21 4184.76,4244.27 3895.76,3761.49C3867.79,3714.77 3838.82,3672.14 3808.93,3633.24C3362.91,3052.65 4215.38,2433.51 4654.24,3486.98C5141.3,4656.15 4719.02,4998.72 4719.02,4998.72C4719.02,4998.72 5071.43,5129.36 4463.51,5399.01Z"
                style="fill:rgb(47,45,44);"
              />
            </g>
            <g transform="matrix(1.06845,0,0,1.11065,47296.2,17734.3)">
              <path
                d="M3861.53,5917.88C4221.42,5350.8 4267.42,4382.35 3895.76,3761.49C3450.47,3017.62 2748.26,3309.49 2209.07,3188.44C1722.34,3079.17 1593.53,2617.43 1679.21,2325.48C1756.34,2062.7 1970.16,1778.08 2566.04,1712.95C3070.93,1657.77 4567.11,2056.89 5005.97,3110.36C5493.03,4279.53 4940.91,5414.56 4940.91,5414.56C4940.91,5414.56 4469.46,5648.23 3861.53,5917.88Z"
                style="fill:rgb(255,221,87);"
              />
              <path
                d="M3861.53,5917.88C4221.42,5350.8 4267.42,4382.35 3895.76,3761.49C3450.47,3017.62 2748.26,3309.49 2209.07,3188.44C1722.34,3079.17 1593.53,2617.43 1679.21,2325.48C1756.34,2062.7 1970.16,1778.08 2566.04,1712.95C3070.93,1657.77 4567.11,2056.89 5005.97,3110.36C5493.03,4279.53 4940.91,5414.56 4940.91,5414.56C4940.91,5414.56 4469.46,5648.23 3861.53,5917.88ZM4155.42,5629.9C4368.96,5027.39 4343.17,4233.08 4017.31,3688.72C3718.02,3188.74 3317.66,3101.76 2912.76,3086.64C2680.71,3077.98 2447.79,3096.84 2240.1,3050.22C1852.71,2963.25 1746.95,2597.74 1815.15,2365.38C1881.08,2140.74 2072.03,1909.45 2581.43,1853.78C2819.04,1827.81 3288.84,1913.41 3751.95,2124.78C4212.84,2335.15 4669.65,2671.41 4875.2,3164.84C5283.47,4144.88 4922.78,5099.64 4832.67,5309.9C4734.19,5358.05 4482.99,5479.74 4155.42,5629.9L4155.42,5629.9Z"
                style="fill:rgb(228,186,59);"
              />
            </g>
            <g
              transform="matrix(3.42109,1.7283,-0.43099,0.921848,6921.75,-10752.2)"
            >
              <g
                transform="matrix(0.280897,0,-1.50382e-17,1.08362,12842.7,5545.77)"
              >
                <path
                  d="M2853.16,2005.82C2869.84,1880.67 2903.2,1630.36 3037.29,1388.78C3193.25,1107.81 3367.3,980.923 3454.33,917.482"
                  style="fill:none;stroke:rgb(176,144,50);stroke-width:146px;"
                />
              </g>
              <g
                transform="matrix(0.275844,-1.05249e-17,2.82037e-17,1.06412,12880.6,5557.79)"
              >
                <circle
                  cx="3460.07"
                  cy="831.072"
                  r="225.802"
                  style="fill:rgb(120,105,63);"
                />
              </g>
              <g
                transform="matrix(-0.258567,0.423409,0.109757,0.997473,14085.2,4646.08)"
              >
                <path
                  d="M2853.16,2005.82C2869.84,1880.67 2903.2,1630.36 3037.29,1388.78C3193.25,1107.81 3367.3,980.923 3454.33,917.482"
                  style="fill:none;stroke:rgb(176,144,50);stroke-width:146px;"
                />
              </g>
              <g
                transform="matrix(-0.253655,0.415366,0.107672,0.978524,14053.6,4735.87)"
              >
                <circle
                  cx="3460.07"
                  cy="831.072"
                  r="225.802"
                  style="fill:rgb(120,105,63);"
                />
              </g>
            </g>
            <g transform="matrix(0.925508,0,0,0.962062,47903.9,18832)">
              <circle
                cx="6012.63"
                cy="5016.71"
                r="84.061"
                style="fill:rgb(47,45,44);"
              />
            </g>
            <g transform="matrix(0.925508,0,0,0.962062,47715.1,18922)">
              <circle
                cx="6012.63"
                cy="5016.71"
                r="84.061"
                style="fill:rgb(47,45,44);"
              />
            </g>
            <g transform="matrix(0.925508,0,0,0.962062,47524.4,19018.9)">
              <circle
                cx="6012.63"
                cy="5016.71"
                r="84.061"
                style="fill:rgb(47,45,44);"
              />
            </g>
            <g transform="matrix(1.05783,0,0,1.09946,47359.2,17601.1)">
              <path
                d="M4138.63,5807.88L4081.95,5992.66L4717.08,5696.54L4821.56,5484.36L4138.8,5807.41L4138.63,5807.88Z"
                style="fill:rgb(253,221,85);"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script lang="ts">
export default {
  name: "IconSluggo",
  props: {
    width: {
      type: Number,
      required: true
    },
    height: {
      type: Number,
      required: true
    }
  }
};
</script>

<style scoped src="./styles.module.scss" lang="scss"></style>
