
import UserProvider from "./UserProvider.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "UserProvidedRouter",
  components: {
    UserProvider
  }
});
