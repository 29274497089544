
import { computed, defineComponent, PropType, ref } from "vue";
import { ReadTeamRecord } from "@/api/types";

export default defineComponent({
  name: "TeamEntry",
  props: {
    team: {
      type: Object as PropType<ReadTeamRecord>,
      required: true
    },
    selectedView: {
      type: Object as PropType<[string, string, string]>,
      required: true
    }
  },
  setup: props => {
    const isSelected = computed<boolean>(
      () => parseInt(props.selectedView[1]) === props.team.id
    );

    // mark the subview as selected if the team is also selected
    const currentSubviewSelection = computed<{ [p: string]: boolean }>(() => ({
      [props.selectedView[2]]: isSelected.value
    }));

    const isOpen = ref<boolean>(false);

    const toggleOpen = () => {
      isOpen.value = !isOpen.value;
    };

    return {
      isSelected,
      isOpen,
      currentSubviewSelection,
      toggleOpen
    };
  }
});
