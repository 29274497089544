
import { defineComponent, ref } from "vue";
import ProfileEmblem from "@/components/ProfileEmblem";
import store from "@/store";
import router from "@/router";
import { LOGOUT_REDIRECT } from "../../../../constants";

export default defineComponent({
  name: "NavbarDropdown",
  props: {
    username: {
      type: String
    }
  },
  components: {
    ProfileEmblem
  },
  setup: () => {
    const isOpen = ref<boolean>(false);

    const handleToggle = () => {
      isOpen.value = !isOpen.value;
    };

    const handleLogout = async () => {
      window.location.replace(LOGOUT_REDIRECT);
    };

    return {
      handleToggle,
      handleLogout,
      isOpen
    };
  }
});
