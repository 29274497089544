
import { defineComponent } from "vue";
import { computed } from "vue";

export default defineComponent({
  name: "ProfileEmblem",
  props: {
    name: {
      type: String,
      required: true
    }
  },
  setup: props => {
    const initials = computed(() =>
      props.name
        .split(" ")
        .map((value: string) => value[0])
        .join("")
    );
    return {
      initials
    };
  }
});
