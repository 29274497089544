
import { defineComponent } from "vue";
const confirmDialogComponent = defineComponent({
  name: "ConfirmDialog",
  emits: ["close", "delete"],
  setup: (props, context) => {
    const cancel = () => {
      context.emit("close");
    };
    const confirm = () => {
      context.emit("close");
      context.emit("delete");
    };
    return {
      cancel,
      confirm
    };
  }
});
export default confirmDialogComponent;
