
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

import store from "@/store";
import { createTeam } from "@/api/teams";

import { generateTeamPageLink } from "@/methods/teamPage";

const newTeamComponent = defineComponent({
  name: "NewTeam",
  setup: function() {
    const router = useRouter();

    const teamName = ref("");

    const teamSubmissionTriggered = async () => {
      const record = await createTeam({
        name: teamName.value.toLowerCase(),
        description: "UNUSED"
      });

      await store.dispatch.doSetTeam(record);

      router.push(generateTeamPageLink(record));
    };

    return {
      teamName,
      teamSubmissionTriggered
    };
  }
});

export default newTeamComponent;
