
import { defineComponent, ref } from "vue";
import ConfirmDialog from "@/components/TicketModal/components/ConfirmDialog/ConfirmDialog.vue";
const footerComponent = defineComponent({
  name: "Footer",
  components: {
    ConfirmDialog
  },
  props: {
    shouldShowDelete: {
      type: Boolean,
      required: true
    }
  },
  emits: ["saveChanges", "closeModal"],
  setup: (props, context) => {
    const confirmModalClass = ref("");
    const saveChanges = () => {
      context.emit("saveChanges");
    };
    const closeModal = () => {
      context.emit("closeModal");
    };
    return {
      confirmModalClass,
      saveChanges,
      closeModal
    };
  }
});
export default footerComponent;
