
import { defineComponent, onMounted, ref } from "vue";
import Card from "@/components/Card";
import { apiExecutor, wrapExceptions } from "@/methods";
import { createTicket, getUsersTeams } from "@/api";
import Loader from "@/components/Loader.vue";
import { ReadTeamRecord } from "@/api/types";

export default defineComponent({
  name: "TicketInput",
  components: {
    Card,
    Loader
  },
  setup: () => {
    const ticketTitle = ref("");
    const selectedTeam = ref<ReadTeamRecord | null>(null);
    const teamErrorMessage = ref<string>("");
    const createTicketSuccess = ref<boolean>(false);
    const createTicketLoading = ref<boolean>(false);
    const ticketErrorMessage = ref<string>("");

    const [
      teamsQuery,
      { loading: teamsLoading, data: teamsData, error: teamsError }
    ] = apiExecutor(getUsersTeams);

    const clearFields = () => {
      ticketTitle.value = "";
      selectedTeam.value = null;
    };

    const clearErrorMessages = () => {
      teamErrorMessage.value = "";
      ticketErrorMessage.value = "";
    };

    const assertInputIsValid = (): boolean => {
      let isValid = true;
      if (!selectedTeam.value) {
        teamErrorMessage.value = "You must select a team.";
        isValid = false;
      }

      if (!ticketTitle.value) {
        ticketErrorMessage.value = "You must specify a title.";
        isValid = false;
      }
      return isValid;
    };

    const onCreate = async () => {
      clearErrorMessages();
      if (!assertInputIsValid()) {
        return;
      }

      createTicketLoading.value = true;
      const [data, error] = await wrapExceptions(
        createTicket,
        { title: ticketTitle.value },
        selectedTeam.value?.id
      );
      createTicketLoading.value = false;

      if (!data || error) {
        return;
      }
      createTicketSuccess.value = true;

      // delay 1 second before resetting the fields and
      // returning to starting state.
      setTimeout(() => {
        createTicketSuccess.value = false;
        clearFields();
        clearErrorMessages();
      }, 1000);
    };

    onMounted(() => {
      teamsQuery();
    });

    return {
      teamsLoading,
      teamsData,
      teamsError,
      selectedTeam,
      ticketTitle,
      createTicketSuccess,
      createTicketLoading,
      teamErrorMessage,
      ticketErrorMessage,
      onCreate
    };
  }
});
