
import { computed, defineComponent, PropType, ref } from "vue";
import ProfileEmblem from "@/components/ProfileEmblem";
import { ReadTicketRecord } from "@/api/types";
import { textColorFromBackground } from "@/methods";

export default defineComponent({
  name: "TicketRow",
  components: { ProfileEmblem },
  props: {
    ticket: {
      type: Object as PropType<ReadTicketRecord>,
      required: true
    }
  },
  setup: props => {
    const statusColor = ref(props.ticket.status?.color ?? "#EEEEEEFF");
    const textColor = computed(() =>
      textColorFromBackground(statusColor.value)
    );
    return {
      textColor,
      statusColor
    };
  }
});
