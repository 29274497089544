
import { defineComponent, ref } from "vue";
import store from "@/store";
import TicketModal from "@/components/TicketModal/TicketModal.vue";
import IconSluggo from "@/assets/IconSluggo";
import NavbarDropdown from "./components/NavbarDropdown.vue";
import Search from "@/components/Search";
import { UserRecord } from "@/api/types";
import Sidebar from "@/views/SluggoNavbar/components/Sidebar.vue";
const sluggoNavbarComponent = defineComponent({
  name: "SluggoNavbar",
  components: {
    IconSluggo,
    TicketModal,
    NavbarDropdown,
    Search,
    Sidebar
  },
  setup: () => {
    const authUser = ref<UserRecord>(store.getters.authUser);
    const isNavMenuOpen = ref<boolean>(false);
    const isSidebarOpen = ref<boolean>(false);
    const modalClass = ref("");
    const handleBurgerClicked = () => {
      isNavMenuOpen.value = !isNavMenuOpen.value;
    };
    const handleBrandClicked = () => {
      isSidebarOpen.value = !isSidebarOpen.value;
    };
    return {
      authUser,
      modalClass,
      isNavMenuOpen,
      isSidebarOpen,
      handleBurgerClicked,
      handleBrandClicked
    };
  }
});
export default sluggoNavbarComponent;
