<template>
  <div id="help-page">
    <section class="section" id="help-section">
      <div class="container">
        <h1 class="title">Help</h1>
        <p>
          Help is not yet implemented in this version of Sluggo. This will be
          implemented at a later date, once the overall functionality of the
          tracker has evolved and matured.
        </p>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Help"
  // components: {
  // }
});
</script>
